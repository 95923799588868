import { Link } from 'gatsby';
import React, { memo } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import sections from '../../../data/leftSections';
import Avatar from '../../shared/Avatar';
import Logo from '../../shared/Logo';
import SectionIcon from '../../shared/SectionIcon';
import styles from './LeftNavbar.module.css';
import UserContext from '../../../contexts/UserContext';

const LeftNavbar = () => {
  const { t } = useTranslation();
  const { user, logout } = React.useContext(UserContext);

  return (
    <div className={styles.container}>
      <div>
        <Avatar />
      </div>

      <hr className="my-6" />

      <div className="grid grid-cols-1 gap-4 text-primary-500">
        {sections.map((x) => (
          <SectionIcon
            key={x.id}
            section={x}
            containerId="LeftSidebar"
            tooltipPlacement="right"
          />
        ))}
      </div>
      <div
        className="grid grid-cols-1 gap-4 text-primary-500"
        style={{ marginTop: '60%' }}
      >
        <button
          onClick={() => {
            logout();
          }}
        >
          logout
        </button>
      </div>
    </div>
  );
};

export default memo(LeftNavbar);
